import * as tf from '@tensorflow/tfjs';
import emojiRegex from 'emoji-regex/RGI_Emoji.js';
import all_emoji from 'emojibase-data/meta/unicode.json';

const regex = emojiRegex();
const emoji_ids = {};
const id_emojis = {};

function parseEmoji(str) {
  //parse emoji from string and memoize codepoints for later use
  let emoji = [];
  let match;
  while ((match = regex.exec(str))) {
    let e = match[0];
    // memoize emoji code points to integer placeholder ID:
    if (!emoji_ids.hasOwnProperty(e)) {
      emoji_ids[e] = Object.keys(emoji_ids).length + 1;
      id_emojis[emoji_ids[e]] = e;
    }
    emoji.push(e);
  }
  return emoji;
}

function parseEmojiIDs(str) {
  let emoji = parseEmoji(str);
  let ids = [];
  for (let char of emoji) {
    ids.push(emoji_ids[char]);
  }
  return ids;
}

function loadEmojiTensor(str, shape) {
  let emojiIDs = parseEmojiIDs(str);
  //reshape will error if emoji count is not a product of shape[0]*shape[1]
  return tf.tensor1d(emojiIDs).reshape(shape);
}

function dumpEmojiTensor(t) {
  let chars = [];
  for (let emojiID of t.flatten().arraySync()) {
    chars.push(id_emojis[emojiID]);
  }
  return [chars.join(''), t.shape];
}

function dumpEmojiArray(t) {
  let chars = [];
  for (let emojiID of t.flatten().arraySync()) {
    chars.push(id_emojis[emojiID]);
  }
  return chars;
}

function randomEmoji(n) {
  let chars = [];
  while(chars.length < n) {
    let rnd_e = all_emoji[Math.floor(Math.random() * all_emoji.length)];
    if (parseEmoji(rnd_e).length > 0) {
      chars.push(rnd_e);
    }
  }
  return chars;
}

export {
  parseEmoji,
  loadEmojiTensor,
  dumpEmojiTensor,
  dumpEmojiArray,
  randomEmoji
}

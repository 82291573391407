<script>
 import { Page } from 'framework7-svelte';
 import Grid from '../components/grid.svelte';
 import { randomEmoji } from '../js/emoji.js';

 let scale = 1;
 let width = Math.floor(window.innerWidth / (200 / scale));
 let height = Math.floor(window.innerHeight / (200 / scale));

 let chars = randomEmoji(width*height);

 chars = randomEmoji(width*height);
</script>

<Page name="home">
    <Grid margin={0} grid_width={width} grid_height={height} chars={chars} />
</Page>

<script>
 import { longpress } from '../js/actions.js';
 import { onMount, createEventDispatcher } from 'svelte';
 import { randomEmoji } from '../js/emoji.js';
 export let size;
 export let contents;
 export let divCell;
 export let scale = 1;
 export let spinner = 0;
 import { f7 } from 'framework7-svelte';

 let parentChildIndex;
 let preventSingleTapAction = false;
 const dispatch = createEventDispatcher();

 let spinInterval;

 function copyContents() {
     let dummy = document.createElement("input");
     document.body.appendChild(dummy);
     dummy.value = divCell.textContent;
     console.log(dummy.value);
     dummy.select();
     document.execCommand("copy");
     document.body.removeChild(dummy);
     let notification = f7.notification.create({
         text: dummy.value + " copied to clipboard",
         closeTimeout: 3000,
     });
     notification.open();
 }

 function handleLongPress(evt) {
     preventSingleTapAction = true;
     startSpinner(10);
     const reenableSingleTap = () => {
         setTimeout(() => {preventSingleTapAction = false;}, 100);
         //only fire once:
         divCell.removeEventListener('mouseup', reenableSingleTap);
         //clearInterval(spinInterval);
     }
     divCell.addEventListener('mouseup', reenableSingleTap);
 }

 function updateContents(str) {
     dispatch('update_cell', {
         index: parentChildIndex,
         contents: str
     });
 }

 function startSpinner(t) {
     if (t === undefined) {
         t = 500;
     }
     clearInterval(spinInterval);
     spinInterval = setInterval(() => {
         updateContents(randomEmoji(1)[0]);
     }, t)
 }

 function stopSpinner() {
     clearInterval(spinInterval);
     spinInterval = undefined;
 }

 function handleSingleTap() {
     if (!preventSingleTapAction) {
         if (spinInterval != undefined) {
             stopSpinner();
         } else {
             updateContents(randomEmoji(1)[0]);
         }
     }
 }

 onMount(()=>{
     parentChildIndex = Array.prototype.indexOf.call(
         divCell.parentNode.children, divCell);
     if (spinner > 0) {
         startSpinner(spinner);
     }
 });

</script>

<style>
 div.cell {
     line-height: 100%;
     text-align: center;
     user-select: none;
     overflow: hidden;
 }
</style>

<div bind:this={divCell} class="cell" style="font-size: {scale * size}px;" on:click={handleSingleTap} use:longpress on:longpress={handleLongPress}>{contents}</div>

<script>
 import Cell from '../components/cell.svelte';
 import { onMount } from 'svelte';
 import { parseEmoji } from '../js/emoji';
 import { watchResize } from 'svelte-watch-resize';

 let client_width;
 let client_height;
 export let grid_width;
 export let grid_height;
 export let chars = "";
 export let cell_gap = 0;
 export let padding_top = 0;
 export let padding_left = 0;
 export let padding_right = 0;
 export let padding_bottom = 0;
 export let margin = "0 auto";

 let cell_size;
 let error_msgs = [];
 let gridElement;

 let emojiArray;
 let emojiLoadError = false;

 function loadEmoji(chars, grid_width, grid_height) {
     let a = parseEmoji(chars)
     if(a.length === grid_width * grid_height) {
         emojiLoadError = false;
     } else {
         emojiLoadError = "Grid input is not rectangular"
     }
     return a;
 }

 onMount(() => {
     client_width = gridElement.clientWidth
     client_height = gridElement.clientHeight
     error_msgs = check_valid(client_width, client_height, grid_width, grid_height);
 });

 function check_valid(width, height, grid_width, grid_height) {
     const error_msgs = []
     if (emojiLoadError) {
         error_msgs.push(emojiLoadError)
     }
     if (width == undefined || height == undefined) {
         error_msgs.push("client width and height undefined")
     }
     return error_msgs
 }

 function handleResize(node) {
     client_width = node.clientWidth;
     client_height = node.clientHeight;
     cell_size = Math.min(client_width/grid_width,
                          client_height/grid_height) - cell_gap
 }

 function handleUpdateCell(evt) {
     emojiArray[evt.detail.index] = evt.detail.contents;
 }

 function randomSpinner() {
     return Math.random() > 0.85 ? Math.max(10, Math.random() * 500) : 0
 }

 $: {
     emojiArray = loadEmoji(chars, grid_width, grid_height)
     cell_size = Math.min(client_width/grid_width,
                          client_height/grid_height) - cell_gap
     error_msgs = check_valid(client_width, client_height, grid_width, grid_height)
 }
</script>

<style>
 .big {
     font-size: 8em;
 }
 .error {
     color: #ff4455;
     font-weight: bold;
     font-size: 2em;
 }
</style>

<div style="padding-top: {padding_top}px;
            padding-left: {padding_left}px;
            padding-right: {padding_right}px;
            padding-bottom: {padding_bottom}px;
            height: 100%;">
    <div bind:this={gridElement} style="display: grid;
         grid-template-columns: repeat({grid_width}, minmax({cell_size}px, {cell_size}px));
         grid-template-rows: repeat({grid_height}, minmax({cell_size}px, {cell_size}px));
         gap: {cell_gap}px;
         margin: {margin};
         height: 100%;
         "
         
         use:watchResize={handleResize} >
        {#if error_msgs.length === 0}
            {#each emojiArray as e}
                <Cell size={cell_size} scale={0.9} contents={e}
                           on:update_cell={handleUpdateCell} spinner={randomSpinner()} />
            {/each}
        {:else}
        <div style="text-align:center; width: 20em;">
            <div class="big">⚠️</div>
            <div class="error">{error_msgs}</div>
        </div>
        {/if}
    </div>
</div>

